import * as ReactRedux from 'react-redux'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'

import * as CombinedSelectors from './combined-selectors'
import * as BaseGameTile from './base-game-tile'
import { Amount } from './amount'

export function CasinoGameTile(props) {
  const translate = Herz.I18n.useTranslate()

  // TODO: Seems useless; check and remove
  const game = ReactRedux.useSelector(state =>
    CombinedSelectors.getGameById(state, { id: props.id })
  )

  const displayJackpot = (props.jackpotValue || game.jackpotValue) > 0

  return (
    <BaseGameTile.Loader size={props.size} title={props.title || game.title}>
      <BaseGameTile.Background
        backgroundUrl={props.backgroundUrl || game.backgroundUrl}
        backgroundOverlayImageAlignment={
          props.backgroundOverlayImageAlignment ||
          game.backgroundOverlayImageAlignment
        }
        backgroundOverlayUrl={
          props.backgroundOverlayUrl || game.backgroundOverlayUrl
        }
        disabled={props.disabled}
        size={props.size}
      >
        <Common.Box
          position="absolute"
          top="0px"
          left="0px"
          bottom="0px"
          right="0px"
          py={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
          background={
            !(props.titleUrl || game.titleUrl)
              ? 'linear-gradient(to bottom,rgba(0,0,0,0),#000000)'
              : 'transparent'
          }
          backgroundPosition="bottom"
          backgroundSize="100% 50%"
          backgroundRepeat="no-repeat"
        >
          <Common.Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            height="100%"
          >
            <BaseGameTile.ProviderLogo
              provider={
                props.provider || game?.displayProvider || game?.provider
              }
              providerLogo={game?.displayProvider || game?.provider}
            />
            {props.titleUrl || game.titleUrl ? (
              <BaseGameTile.TitleImage
                titleUrl={props.titleUrl || game.titleUrl}
                size={props.size === 'medium' ? 'small' : props.size}
                alt={props.title || game.title}
              />
            ) : (
              <BaseGameTile.Title size={props.size}>
                {props.title || game.title}
              </BaseGameTile.Title>
            )}
          </Common.Box>
        </Common.Box>
        {!props.hideJackpot && displayJackpot && (
          <BaseGameTile.Jackpot>
            <React.Fragment>
              <Common.Space pr={0}>
                {translate('game-tile.jackpot-value')}
              </Common.Space>
              <Amount currency={props.jackpotCurrency || game.jackpotCurrency}>
                {Number.parseInt(props.jackpotValue || game.jackpotValue, 10)}
              </Amount>
            </React.Fragment>
          </BaseGameTile.Jackpot>
        )}
      </BaseGameTile.Background>
    </BaseGameTile.Loader>
  )
}

CasinoGameTile.propTypes = {
  backgroundOverlayImageAlignment: PropTypes.string,
  backgroundOverlayUrl: PropTypes.string,
  backgroundUrl: PropTypes.string,
  disabled: PropTypes.bool,
  hideJackpot: PropTypes.bool,
  id: PropTypes.number,
  jackpotCurrency: PropTypes.string,
  jackpotValue: PropTypes.number,
  provider: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'picked-games']),
  title: PropTypes.string,
  titleUrl: PropTypes.string,
  providerLogo: PropTypes.string,
}
